/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { TemplateRowThemesWindProps } from "./types";
import { FormEvent, useCallback, useRef, useState } from "react";
import addgreyicon from "../../../Assets/addgreyicon.svg";
import Icon from "components/atoms/icons";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import IconButton from "components/atoms/icon-button";
import Tooltip from "components/atoms/tooltip";
import { useDebounce } from "react-use";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "utils/icons";
import { useGenerateWinThemes } from "./hooks";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { WinTheme } from "components/copilot/CopilotSchemaImmutableTypes";
import { useNotification } from "context/notificationContext";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { find, findIndex, LiveList, LiveObject, moveItem } from "YJSProvider/LiveObjects";
import { useSearchParams } from "react-router-dom";
import { Storage } from "components/copilot/CopilotSchemaTypes";

/* ---------------------- Win Themes Content -------------------- */
const WinThemesContent = () => {
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id") || "";
  const winThemes = (useStorage((root) => root.win_themes) as WinTheme[]) || [];
  const [isGenerating, setIsGenerating] = useState(false);
  const { setToast } = useNotification();
  const trackUserEvent = useTrackUserMetric();

  const [templateRowEditable, setTemplateRowEditable] = useState<string | null>(null);

  // close editing
  const closeEditMode = useCallback((e: FormEvent<HTMLFormElement> | undefined) => {
    if (e) {
      e.preventDefault();
    }
    setTemplateRowEditable(null);
  }, []);

  const onDragEnd = useMutation(({ storage }, result: DropResult) => {
    if (result.type !== "PROPOSAL_PLAN_WIN_THEMES") return;
    const winThemes = storage?.get("win_themes") as Storage["win_themes"];
    const { source, destination } = result;

    if (!destination) {
      return; // The item was dropped outside of a valid drop target
    }

    if (winThemes) {
      moveItem(winThemes, source.index, destination.index);
    }
  }, []);

  // on update win theme
  const updateWinTheme = useMutation(({ storage }, id: string, newTitle: string) => {
    const winThemes = storage?.get("win_themes") as Storage["win_themes"];
    if (!winThemes) return;
    const liveWinTheme = find(winThemes, (winTheme) => winTheme.get("id") === id);

    liveWinTheme?.set("content", newTitle);
  }, []);

  // on add win theme
  const addWinTheme = useMutation(
    ({ storage }, content: string = "") => {
      const winThemes = storage?.get("win_themes") as Storage["win_themes"];
      const id = uuidv4();
      const newWinTheme = new LiveObject({ id, content });

      if (winThemes?.length) {
        winThemes.push([newWinTheme]);
      } else {
        storage?.set("win_themes", new LiveList([newWinTheme]));
      }
      if (!content) {
        setTemplateRowEditable(id);
        trackUserEvent("Projects: Empty Win Theme Added", {
          internal_contract_id: internalContractId,
        });
      }
    },
    [internalContractId]
  );

  // on remove win theme
  const removeWinTheme = useMutation(({ storage }, id: string) => {
    const winThemes = storage?.get("win_themes") as Storage["win_themes"];
    if (!winThemes) return;
    const liveWinThemeIndex = findIndex(winThemes, (winTheme) => winTheme.get("id") === id);

    if (liveWinThemeIndex !== -1) winThemes?.delete(liveWinThemeIndex);
  }, []);

  const generateWinThemes = useGenerateWinThemes({
    onmessage(msg) {
      if (msg.event === "FatalError") {
      }

      if (!!msg.data?.length) {
        addWinTheme(msg.data);

        trackUserEvent("Projects: Win Theme Generated", {
          word_count: getWordCount(msg.data),
          internal_contract_id: internalContractId,
        });
      }
    },
    async onopen(response) {
      if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
        return; // everything's good
      } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
        setToast.error({
          title: "Unable to generate win themes",
          msg: "There was an issue generating win themes. Please refresh and try again.",
        });
        setIsGenerating(false);
      }
    },
    onclose() {
      setIsGenerating(false);
    },
    onerror(err) {
      setToast.error({
        title: "Unable to generate win themes",
        msg: "There was an issue generating win themes. Please refresh and try again.",
      });
      setIsGenerating(false);
      if (err instanceof Error) {
        throw err; // rethrow to stop the operation
      }
    },
  });

  const buttonText = winThemes.length < 1 ? "Generate win themes" : "Generate more win themes.";
  const buttonSuffix = winThemes.length < 1 ? "to get started." : "";

  return (
    <div className="pt-5">
      {/* header */}
      <div className="text-gray-text text-sm pb-1 flex flex-wrap items-center gap-[0.2rem]">
        Provide the win themes for this proposal.{" "}
        <button
          onClick={() => {
            setIsGenerating(true);
            generateWinThemes(internalContractId);
          }}
          className="font-semibold max-w-full text-action text-sm duration-100 hover:text-action-hover"
        >
          {buttonText}
        </button>
        {buttonSuffix}
      </div>
      <div className="w-full py-2 cursor-default">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" type="PROPOSAL_PLAN_WIN_THEMES">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {winThemes.map((item, index) => (
                  <TemplateRowThemesWind
                    index={index}
                    key={item.id}
                    onCloseEdit={(e) => closeEditMode(e)}
                    editable
                    onEdit={(newTitle) => updateWinTheme(item.id, newTitle)}
                    onOpenEdit={() => setTemplateRowEditable(`${item.id}`)}
                    isEditActive={templateRowEditable === item.id}
                    onDelete={() => {
                      removeWinTheme(item.id);
                    }}
                    draggableId={item.id}
                    data={item}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {isGenerating ? (
            <div className="p-3 my-auto flex items-center justify-center">
              <Spinner classes="!text-black" />
            </div>
          ) : (
            <div
              className="w-full flex items-center select-none gap-2.5 cursor-default"
              role="button"
              onClick={() => addWinTheme()}
            >
              <div className="text-[#5B6B79] p-2 pl-4 flex flex-1 items-center text-sm gap-2 cursor-pointer h-[54px] bg-gray-100 rounded-md border border-gray-200 duration-200 hover:bg-gray-200">
                <div className="">
                  <img alt="Plus" className="text-[#9FA2AA]" src={addgreyicon} />
                </div>
                Write a new win theme
              </div>
            </div>
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

/*-------------------template row themes win --------------------- */

const TemplateRowThemesWind = ({
  data,
  index,
  editable,
  onOpenEdit,
  onCloseEdit,
  onDelete,
  onEdit,
  isEditActive,
  draggableId,
}: TemplateRowThemesWindProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [rowTitle, setRowTitle] = useState(data.content);

  const firstUpdate = useRef(true);
  useDebounce(
    () => {
      if (rowTitle !== data.content && !firstUpdate.current && editable) {
        onEdit?.(rowTitle);
      }
      firstUpdate.current = false;
    },
    200,
    [rowTitle]
  );
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="!outline-0 flex flex-col w-full pb-2 !cursor-default active:cursor-grabbing"
        >
          <div
            className="cursor-pointer relative group flex items-center pl-3 py-2 pr-4 justify-center flex-1 min-h-[54px] bg-gray-100 text-stone-900 text-xl font-medium rounded-md overflow-hidden"
            css={[tw`text-gray-500 bg-gray-100 text-base font-normal outline-transparent`]}
          >
            <div className="flex gap-2 items-center">
              <div className="flex flex-col items-center">
                <div>
                  <Tooltip
                    content={
                      <div>
                        <span className="text-gray-800 text-xs font-semibold">Drag</span>
                        <span className="text-gray-800 text-xs font-normal"> to move</span>
                      </div>
                    }
                  >
                    <div className="flex cursor-grab justify-center items-center p-1 rounded-md bg-transparent duration-150 hover:bg-slate-200 active:bg-slate-200 active:cursor-grabbing">
                      <Icon name="Draggable" className="text-gray-lightest" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex w-full items-center justify-between gap-4 p-2.5 text-sm">
              {isEditActive && editable ? (
                <input
                  type="text"
                  className="z-[1] w-full bg-transparent focus:outline-none border-0 rounded-0 focus:ring-0 ring-0"
                  placeholder=""
                  value={rowTitle}
                  onChange={(e) => setRowTitle(e.target.value)}
                  autoFocus={isEditActive}
                  onBlur={() => onCloseEdit?.()}
                  onKeyDown={(e) => e.code === "Enter" && onCloseEdit?.()}
                />
              ) : (
                <div ref={contentRef} className="flex items-center gap-2 z-[1]" css={[tw`line-clamp-8`]}>
                  {data.content}
                </div>
              )}
            </div>

            {isEditActive && editable ? null : (
              <div className="flex items-center gap-0.5">
                {editable && (
                  <div
                    className="align-middle relative z-[1] inline-flex justify-center items-center cursor-pointer select-none opacity-0 group-hover:opacity-100"
                    role="button"
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenEdit?.();
                      }}
                      name="Pencil"
                      className="!inline-flex p-1 h-6 w-6 min-w-[24px] rounded-md transition-colors duration-100 text-zinc-600 hover:bg-gray-200"
                    />
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete?.();
                      }}
                      name="MinusCircle"
                      className="!inline-flex p-1 h-6 w-6 min-w-[24px] rounded-md transition-colors duration-100 text-zinc-600 hover:bg-gray-200"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default WinThemesContent;
